/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
// import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  makeStyles,
  Typography,
  Grid,
  FormControl,
  OutlinedInput,
  IconButton,
  InputLabel,
  FormHelperText,
  Link
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { setCurrentUser } from 'src/api/_common';

import api from 'src/services/axios';

import Alert from 'src/components/alert';
import RichText from 'src/components/richText';
import PhoneInput from 'src/components/phoneInput';
import { currentStep, nextStep } from 'src/features/onboarding/slice';
import StepsNavigation, { NextButtonTrustedForm } from 'src/features/onboarding/stepsNavigation';

import onboarding from 'src/api/onboarding';

const useStyles = makeStyles(theme => ({
  form: {
    maxWidth: 700,
    margin: 'auto',
  },
  controlsWrapper: {
    maxWidth: 474,
    margin: 'auto',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  icon: {
    color: theme.palette.primary.main,
  },
  apiAlert: {
    margin: theme.spacing(2, 0),
  },
  link: {
    cursor: 'pointer',
  }
}));

const initialValues = {
  email: '',
  company: '',
  phone: '',
  password: '',
  plan: '',
  countryCodeId: '',
};

const Step1 = props => {
  const classes = useStyles();

  const [countries, setCountries] = useState([]);
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isCreatedUser, setIsCreatedUser] = useState(false);

  const validationSchema = yup.object().shape({
    countryCodeId: yup.string(),
    email: yup
      .string()
      .email()
      .required('This field is required.'),
    company: yup
      .string()
      .required('This field is required.'),
    phone: yup
      .string()
      .required('This field is required.')
      .test('phone_check', function (value) {
        let pattern = '';

        const countryCodeIdValue = this.resolve(yup.ref('countryCodeId'));
        const phoneValue = this.resolve(yup.ref('phone'));
        const country = countries.find(countryItem => countryItem.country_code === countryCodeIdValue);

        if (!country?.reg_pattern || !phoneValue || countries.length < 1) {
          return true;
        }

        if (country?.reg_pattern) {
          pattern = country.reg_pattern.replace('/^', '^').replace('$/', '$');
        }

        if (pattern != '' && !phoneValue.replaceAll('-', '').match(new RegExp(pattern), 'Incorrect phone number.')) {
          return this.createError({
            message: `Incorrect phone number.`,
            path: 'phone',
          });
        } else {
          return true;
        }
      }),
    password: yup
      .string()
      .min(8, 'Password must be contain at least 8 characters')
      .matches(/[0-9]/, 'Password requires at least one digit')
      .matches(/[a-z]/, 'Password requires at least one lowercase letter')
      .matches(/[A-Z]/, 'Password requires at least one uppercase letter')
      .matches(/[^\w]/, 'Password requires at least one special symbol')
      .required('Password is required'),
    plan: null, //.required(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async values => {
      // window.trustedFormStartRecording();

      const trustedFormUrlElement = document.getElementsByName('xxTrustedFormCertUrl');
      const trustedFormUrlElementValue = trustedFormUrlElement[0] && trustedFormUrlElement[0].value ? trustedFormUrlElement[0].value : '';

      const { status, message } = await onboarding.registerUser({ ...formik.values, trustedFormUrl: trustedFormUrlElementValue });

      if (status === 'success') {
        window.trustedFormStopRecording();
        window.trustedFormNext();

        setIsCreatedUser(true);
      } else {
        // window.trustedFormStopRecording();

        setError(message);
      }
    },
  });

  const prismicContent = useStaticQuery(graphql`
    query {
      allPrismicOnboarding {
        edges {
          node {
            data {
              title1 {
                html
                text
                raw
              }
              text1 {
                html
                text
                raw
              }
            }
          }
        }
      },
      arrowRightGray: file(relativePath: { eq: "arrow-right-gray.png" }) {
        childImageSharp {
          fluid(pngQuality: 100, maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      arrowRightWhite: file(relativePath: { eq: "arrow-right-white.png" }) {
        childImageSharp {
          fluid(pngQuality: 100, maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const prismicData = prismicContent.allPrismicOnboarding.edges[0].node.data;

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChangePhone = (fieldName, value, countryCodeId) => {
    formik.setValues({ ...formik.values, phone: value, countryCodeId }, true);
  };

  const handleGetCountriesList = (data) => {
    if (data.length < 0) {
      return true;
    }

    setCountries(data);

    return true;
  };

  useEffect(() => {
    const formEmailElement = document.getElementsByName('email');
    const formPhoneElement = document.getElementsByName('phone');

    if (formEmailElement.length > 0) {
      formEmailElement[0].setAttribute('data-tf-element-role', 'consent-grantor-email');
    }
    if (formPhoneElement.length > 0) {
      formPhoneElement[0].setAttribute('data-tf-element-role', 'consent-grantor-phone');
    }
  }, []);

  useEffect(() => {
    const scriptElement = document.getElementById('trustedform_id');

    if (!scriptElement) {
      const body = document.querySelector('body');
      const script = document.createElement('script');
      const noscript = document.createElement('noscript');
      const img = document.createElement('img');

      script.id = 'trustedform_id';
      img.setAttribute('src', 'https://api.trustedform.com/ns.gif');
      noscript.appendChild(img);

      script.appendChild(document.createTextNode(`(function() {
        var tf = document.createElement('script');
        tf.type = 'text/javascript';
        tf.async = true;
        tf.src = ("https:" == document.location.protocol ? 'https' : 'http') +
          '://api.trustedform.com/trustedform.js?field=xxTrustedFormCertUrl&ping_field=xxTrustedFormPingUrl&use_tagged_consent=true${process.env.TRUSTED_FORM_SANDBOX == "true" ? "&sandbox=true" : ""}&l=' +
          new Date().getTime() + Math.random();
        var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(tf, s);
      })();`));

      body.appendChild(script);
      body.appendChild(noscript);
    } else {
      window.trustedFormNext();
    }
  }, []);

  useEffect(() => {
    props.currentStep({ activeStep: 1 });
  }, []);

  useEffect(() => {
    const plan = sessionStorage.getItem('plan');

    if (!plan || '' === plan) {
      formik.setFieldValue('plan', null);
    }
    formik.setFieldValue('plan', plan);
  }, []);


  useEffect(async () => {
    if (!isCreatedUser) {
      return;
    }

    await api.get('/me').then(({ data: me }) => {
      setCurrentUser(me.data.user);

      try {
        $FPROM.trackSignup(
          {
            email: me.data.user.email
          },
          function () {
            console.log('Callback received!')
          }
        );
      } catch {
        console.log('Firstpromoter not work.');
      }

      if (!window.dataLayer) {
        window.dataLayer = [];
      }

      window.dataLayer.push(
        {
          "identity": {
            "account": {
              "id": me.data.user.id,
            },
            "user": {
              "id": me.data.user.id,
            },
          },
          "context": {
            "staging": process.env.DEVELOPMENT_LOGOUT,
            "impersonating": "false",
            "role": me.data.user.user_role,// "user"
          },
          "accountProperties": {
            "created_at": new Date(),
          }
        }
      );

      window.dataLayer.push({
        "event": "signup",
        "context": {
          "staging": process.env.DEVELOPMENT_LOGOUT,
        },
        "accountProperties": {
          "created_at": Date.now(),
        }
      });

      sessionStorage.setItem('step4', JSON.stringify({ ...formik.values }));

      props.nextStep();
    });
  }, [isCreatedUser]);

  const openHelpChat = () => {
    const elements = document.getElementById('customerly-container').getElementsByTagName('iframe');

    if (elements.length < 1) {
      return;
    }

    elements[0].contentWindow.document.getElementsByTagName("button")[0].click();
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit} className={classes.form} data-tf-element-role="offer">
        <Grid container>
          <Typography variant="h1">{prismicData.title1.text}</Typography>

          <Typography variant="subtitle1">
            <RichText render={prismicData.text1.raw} />
          </Typography>
        </Grid>

        <FormHelperText component="div" className={classes.apiAlert}>
          {error && <Alert severity="error">{error}</Alert>}
        </FormHelperText>

        <div className={classes.controlsWrapper}>
          <FormControl variant="outlined" fullWidth margin="normal">
            <InputLabel htmlFor="email">Enter your email</InputLabel>

            <OutlinedInput
              id="email"
              label="Enter your email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={formik.errors.email && formik.touched.email}
            />

            {formik.errors.email && formik.touched.email ? (
              <FormHelperText component="div">
                <Alert severity="error">{formik.errors.email}</Alert>
              </FormHelperText>
            ) : null}
          </FormControl>

          <FormControl variant="outlined" fullWidth margin="normal">
            <InputLabel htmlFor="company">Enter your company name</InputLabel>

            <OutlinedInput
              id="company"
              data-tf-element-role="consent-grantor-name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label="Enter your company name"
              name="company"
              value={formik.values.company}
              error={formik.errors.company && formik.touched.company}
            />

            {formik.errors.company && formik.touched.company ? (
              <FormHelperText component="div">
                <Alert severity="error">{formik.errors.company}</Alert>
              </FormHelperText>
            ) : null}
          </FormControl>

          <FormControl variant="outlined" fullWidth margin="normal">
            <PhoneInput
              country
              name="phone"
              value={formik.values.phone}
              countriesList={handleGetCountriesList}
              onChange={handleChangePhone}
              onBlur={formik.handleBlur}
              error={formik.errors.phone && formik.touched.phone}
            />

            {formik.errors.phone && formik.touched.phone ? (
              <FormHelperText component="div" className={classes.textHelper}>
                <Alert severity="error">{formik.errors.phone}</Alert>
              </FormHelperText>
            ) : null}
          </FormControl>

          <Typography component="div" variant="caption" style={{marginTop:'8px',lineHeight:'15px'}}>
            <div style={{marginBottom:'5px'}}>
              By providing your number, you agree to <span data-tf-element-role="consent-grantor-waived-regulated-technologies"><span data-tf-element-role="contact-method">receive business calls and texts</span> from <span data-tf-element-role="consent-advertiser-name">Leverly</span>, including automated messages</span>, to the number you provide. Message and data rates may apply. Consent is not required for purchase.
            </div>
            <div style={{marginBottom:'5px'}}>
              For more info: <Link className={classes.link} href="https://leverly.com/privacy-policy" target="_blank">Privacy Policy</Link> | <Link className={classes.link} href="https://leverly.com/terms-and-conditions" target="_blank">Terms of Service</Link>
            </div>
            <div style={{marginBottom:'5px'}}>
              To opt-out, text "STOP". For help, text "HELP".
            </div>
            <div>
              For international leads and customers, contact <Link className={classes.link} onClick={openHelpChat}>Customer Success</Link>.
            </div>
          </Typography>

          <FormControl variant="outlined" fullWidth margin="normal" style={{marginBottom: '2px'}}>
            <InputLabel htmlFor="password">Create your password</InputLabel>

            <OutlinedInput
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label="Create your password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              value={formik.values.password}
              error={formik.errors.password && formik.touched.password}
              endAdornment={
                <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                  {showPassword ? <VisibilityOff className={classes.icon} /> : <Visibility className={classes.icon} />}
                </IconButton>
              }
            />

            <FormHelperText component="div">
              {formik.errors.password && formik.touched.password ? (
                <Alert variant="filled" severity="error">
                  {formik.errors.password}
                </Alert>
              ) : null}
            </FormHelperText>
          </FormControl>

          <Typography component="div" variant="caption">
            <div style={{width:'100%',display:'block',lineHeight:'15px'}}>
              <ul style={{columns:2,marginTop:0,paddingLeft:'10px'}}>
                <li>8 characters minimum</li>
                <li>One lowercase letter</li>
                <li>One uppercase letter</li>
                <li>One special symbol</li>
                <li>One number</li>
              </ul>
            </div>
          </Typography>
        </div>

        <StepsNavigation>
          <NextButtonTrustedForm
            arrowImage={`url(${prismicContent['arrowRightWhite'].childImageSharp.fluid.base64})`}
            arrowImageDisabled={`url(${prismicContent['arrowRightGray'].childImageSharp.fluid.base64})`}
            data-tf-element-role="submit"
            disabled={!formik.isValid}
          />
        </StepsNavigation>
      </form>
    </>
  );
};

const mapDispatchToProps = { currentStep, nextStep };

export default connect(null, mapDispatchToProps)(Step1);
